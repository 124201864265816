<div class="h-100vh pt-50 pb-50" [class.component-dark-theme]="themeService.isDark()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.card-border-radius]="themeService.isCardBorderRadius()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="tagus-form ms-auto me-auto">
                <div class="title">
                    <div class="d-flex mb-10 align-items-center">
                        <h2 class="mb-0 me-30">Get Started</h2>
                        <img src="img/logo-icon.png" alt="logo-icon">
                    </div>
                    <p>Already have an account? <a routerLink="/authentication" class="main-color fw-medium">Login
                            now!</a></p>
                </div>

                <form [formGroup]="registerForm" (ngSubmit)="onContinue()">
                    <div class="bg-white border-radius pt-20 ps-20 pe-20">

                        <!-- Email Field -->
                        <div class="tagus-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold gray-color">Email Address <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <mat-label>Enter email address</mat-label>
                                <input matInput formControlName="email">
                            </mat-form-field>
                            <!-- <p *ngIf="emailChecked === true" class="text-danger">Email already exists!</p> -->
                            <!-- <p *ngIf="emailChecked === false" class="text-success">Email is available!</p> -->
                        </div>

                        <!-- Password Fields (Show Only if Email is Available) -->
                        @if(emailChecked){
                        <div class="tagus-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold gray-color">Password <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <mat-label>Enter your password</mat-label>
                                <input matInput formControlName="password" [type]="hide ? 'password' : 'text'">
                                <button mat-icon-button matSuffix (click)="hide = !hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>

                        <div class="tagus-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold gray-color">Confirm Password
                                <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <mat-label>Confirm your password</mat-label>
                                <input matInput formControlName="confirmPassword" [type]="hide ? 'password' : 'text'">
                                <button mat-icon-button matSuffix (click)="hide = !hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>
                            <p *ngIf="registerForm.hasError('notMatching') && registerForm.get('confirmPassword')?.touched"
                                class="text-danger">
                                Passwords do not match!
                            </p>
                        </div>
                        }

                    </div>

                    <button mat-flat-button class="tagus d-block fw-semibold" type="submit" [disabled]="loading()">


                        <app-loading class="d-flex justify-content-center" [loading]="loading()" [size]="19">
                            Continue</app-loading>
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>