<div class="sidebar-area bg-white box-shadow" [ngClass]="{'active': isToggled}"
    [class.dark-sidebar]="themeService.isSidebarDark()" [class.right-sidebar]="themeService.isRightSidebar()"
    [class.hide-sidebar]="themeService.isHideSidebar()">
    <div class="logo bg-white">
        <a routerLink="/accounts" class="d-flex align-items-center">
            <img src="img/logo-icon.png" alt="logo">
            <!-- <span>Tagus</span> -->
        </a>
    </div>
    <div class="burger-menu" (click)="toggle()" [ngClass]="{'active': isToggled}">
        <span class="top-bar"></span>
        <span class="middle-bar"></span>
        <span class="bottom-bar"></span>
    </div>
    <ng-scrollbar visibility="hover" style="height: 100vh;">
        <div class="sidebar-inner">
            <div class="sidebar-menu">
                <mat-accordion>
                    <a routerLink="/accounts" class="sidebar-menu-link" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">
                        <i class="flaticon-user-1"></i>
                        <span class="title">Accounts</span>
                    </a>

                    <!-- logout -->
                    <a (click)="logout()" class="sidebar-menu-link" routerLinkActive="active" style="cursor: pointer;"
                        [routerLinkActiveOptions]="{exact: true}">
                        <!-- <i class="ri-shut-down-line"></i> -->
                        <span class="material-symbols-outlined">
                            logout
                        </span>
                        <span class="title">Logout</span>
                    </a>
                </mat-accordion>
            </div>
        </div>
    </ng-scrollbar>
</div>