import { Component, inject } from '@angular/core';
import { CustomizerSettingsService } from '../../customizer-settings/customizer-settings.service';
import { Router, RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AuthService } from '../../../core/services/auth.service';
import { catchError, concatMap, map, switchMap, tap, throwError } from 'rxjs';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { NgIf } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RegistrationStatusEnum } from '../enums/registration-status';

// Custom email validation function
function validateEmail(control: FormControl) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = emailPattern.test(control.value);
    return isValid ? null : { invalidEmail: true };
}

@Component({
    selector: 'app-forgot-password',
    imports: [
        RouterLink,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        NgIf,
    ],
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
    private readonly authService = inject(AuthService);
    private readonly router = inject(Router);

    emailForm: FormGroup;

    constructor(
        public themeService: CustomizerSettingsService,
        private readonly fb: FormBuilder,
        private readonly _snackBar: MatSnackBar
    ) {
        this.emailForm = this.fb.group({
            email: ['', [Validators.required, validateEmail]],
        });
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    toggleCardBorderTheme() {
        this.themeService.toggleCardBorderTheme();
    }

    toggleCardBorderRadiusTheme() {
        this.themeService.toggleCardBorderRadiusTheme();
    }

    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }

    recoverPassword() {
        this.authService
            .getClientTokenDetails()
            .pipe(
                tap((token) =>
                    this.authService.setClientToken(token.access_token)
                ),
                switchMap(() =>
                    this.authService.getUserByEmail(
                        this.emailForm.get(`email`)?.value
                    )
                ),
                concatMap((userData) => {
                    console.log(userData.length);
                    if (userData.length === 0) {
                        throw new Error('User not found');
                    }
                    if (!userData[0].emailVerified) {
                        throw new Error('User not verified');
                    }
                    return this.authService
                        .sendResetPasswordLinkUsingKeycloak(userData[0].id)
                        .pipe(map(() => userData));
                }),
                catchError((error: string) => {
                    console.log(error);
                    this._snackBar.open(error, 'Close');
                    return throwError(() => new Error(error));
                })
            )
            .subscribe((userData) => {
                this.router.navigate(
                    ['/authentication/forgot-password-recovery'],
                    {
                        queryParams: {
                            email: userData[0].email,
                            status: RegistrationStatusEnum.FORGOT_PASSWORD,
                        },
                    }
                );
            });
    }
}
