<!-- <div
    class="h-100vh pt-50 pb-50"
    [class.component-dark-theme]="themeService.isDark()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.card-border-radius]="themeService.isCardBorderRadius()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="tagus-form ms-auto me-auto">
                <div class="title">
                    <div class="d-flex mb-10 align-items-center">
                        <h2 class="mb-0 me-30">Get Started</h2>
                        <img src="img/logo-icon.png" alt="logo-icon">
                    </div>
                    <p>Don’t have an account? <a routerLink="/authentication/register" class="main-color fw-medium">Register now!</a></p>
                </div>
                <form>
                    <div class="login-with-socials d-md-flex align-items-center justify-content-space-between">
                        <button mat-flat-button class="gray fw-semibold d-block me-5"><img src="img/icon/google.png" alt="google"> Login with Google</button>
                        <button mat-flat-button class="tagus fw-semibold d-block ms-5"><img src="img/icon/facebook.png" alt="facebook"> Login with Facebook</button>
                    </div>
                    <div class="or text-center position-relative">
                        <span class="fw-semibold fs-16">or</span>
                    </div>
                    <div class="bg-white border-radius pt-20 ps-20 pe-20">
                        <div class="tagus-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold gray-color">Email Address <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <mat-label>Enter email address</mat-label>
                                <input matInput>
                            </mat-form-field>
                        </div>
                        <div class="tagus-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold gray-color">Password <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <mat-label>Enter your password</mat-label>
                                <input matInput [type]="hide ? 'password' : 'text'">
                                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="d-flex info align-items-center justify-content-space-between">
                        <mat-checkbox class="gray-color">Remember me</mat-checkbox>
                        <a routerLink="/authentication/forgot-password" class="d-inline-block main-color fw-medium">Forgot your password?</a>
                    </div>
                    <button mat-flat-button class="tagus d-block fw-semibold">Log In</button>
                </form>
            </div>
        </div>
    </div>
</div> -->

<div class="h-100vh pt-50 pb-50" [class.component-dark-theme]="themeService.isDark()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.card-border-radius]="themeService.isCardBorderRadius()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="tagus-form ms-auto me-auto">
                <div class="title">
                    <div class="d-flex mb-10 align-items-center">
                        <h2 class="mb-0 me-10">Get Started</h2>
                        <img src="img/logo-icon.png" alt="logo-icon">
                    </div>
                    <p>Don’t have an account?
                        <a routerLink="/authentication/register" class="main-color fw-medium">Register now!</a>
                    </p>
                </div>

                <div class="position-relative">
                    <!-- @if(loading()){
                    <div class="custom-progress-bar position-absolute">
                        <div class="progress-bar-fill"></div>
                    </div>

                    <div class="overlay-bar"></div>
                    } -->
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="login-with-socials d-md-flex align-items-center justify-content-space-between">
                            <button mat-flat-button class="gray fw-semibold d-block me-5" type="button"
                                (click)="loginWithSocial($event,'google')">
                                <app-loading [loading]="isGoogleLoading || provider() === 'google'" [size]="18"
                                    [text]="'Authenticating...'" class="d-flex justify-content-center w-full">
                                    <img src="img/icon/google.png" alt="google">
                                    Login with Google
                                </app-loading>
                            </button>
                            <button mat-flat-button class="gray fw-semibold d-block me-5" type="button"
                                (click)="loginWithSocial($event,'microsoft')">
                                <app-loading class="d-flex justify-content-center"
                                    [loading]="isMicrosoftLoading || provider() === 'microsoft'" [size]="18"
                                    [text]="'Authenticating...'">
                                    <img src="img/icon/microsoft.png" alt="microsoft">
                                    Login with Microsoft
                                </app-loading>
                            </button>
                        </div>

                        <div class="or text-center position-relative">
                            <span class="fw-semibold fs-16">or</span>
                        </div>

                        <div class="bg-white border-radius pt-20 ps-20 pe-20">
                            <!-- Email Field -->
                            <div class="tagus-form-group without-icon">
                                <label class="d-block mb-12 fw-semibold gray-color">Email Address <span>*</span></label>
                                <mat-form-field appearance="fill">
                                    <mat-label>Enter email address</mat-label>
                                    <input matInput formControlName="userName">
                                </mat-form-field>
                                <div *ngIf="loginForm.get('userName')?.invalid && loginForm.get('userName')?.touched"
                                    class="text-danger">
                                    <small *ngIf="loginForm.get('userName')?.errors?.['required']">Email is
                                        required</small>
                                    <small *ngIf="loginForm.get('userName')?.errors?.['userName']">Invalid email
                                        address</small>
                                </div>
                            </div>

                            <!-- Password Field -->
                            <div class="tagus-form-group without-icon">
                                <label class="d-block mb-12 fw-semibold gray-color">Password <span>*</span></label>
                                <mat-form-field appearance="fill">
                                    <mat-label>Enter your password</mat-label>
                                    <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
                                    <button mat-icon-button matSuffix (click)="hide = !hide"
                                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                        <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                                    </button>
                                </mat-form-field>
                                <div *ngIf="loginForm.get('password')?.invalid && loginForm.get('password')?.touched"
                                    class="text-danger">
                                    <small *ngIf="loginForm.get('password')?.errors?.['required']">Password is
                                        required</small>
                                    <small *ngIf="loginForm.get('password')?.errors?.['minlength']">Password must be at
                                        least 6 characters</small>
                                </div>
                            </div>
                        </div>

                        <!-- Remember Me & Forgot Password -->
                        <div class="d-flex info align-items-center justify-content-end">
                            <!-- <mat-checkbox formControlName="rememberMe" class="gray-color">Remember me</mat-checkbox> -->
                            <a routerLink="/authentication/forgot-password"
                                class="d-inline-block main-color fw-medium">Forgot your password?</a>
                        </div>

                        <!-- Submit Button -->
                        <button mat-flat-button class="tagus d-block fw-semibold" type="submit"
                            [disabled]="!loginForm.valid">
                            <app-loading class="d-flex justify-content-center" [loading]="loading()" [size]="19">Log
                                In</app-loading>

                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>