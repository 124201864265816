import { Component } from '@angular/core';
import { ToggleService } from '../header/toggle.service';
import { CustomizerSettingsService } from '../../customizer-settings/customizer-settings.service';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgClass } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { AuthService } from '../../../core/services/auth.service';

@Component({
    selector: 'app-sidebar',
    imports: [
        RouterLink,
        NgClass,
        NgScrollbarModule,
        MatExpansionModule,
        RouterLinkActive,
    ],
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
    panelOpenState = false;

    isToggled = false;

    constructor(
        private readonly toggleService: ToggleService,
        public themeService: CustomizerSettingsService,
        private readonly authService: AuthService
    ) {
        this.toggleService.isToggled$.subscribe((isToggled) => {
            this.isToggled = isToggled;
        });
    }

    toggle() {
        this.toggleService.toggle();
    }

    toggleSidebarTheme() {
        this.themeService.toggleSidebarTheme();
    }

    toggleHideSidebarTheme() {
        this.themeService.toggleHideSidebarTheme();
    }

    logout() {
        this.authService
            .logout(this.authService.getOauthDetail()?.userID)
            .subscribe();
    }
}
