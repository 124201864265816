import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service'; // Adjust path as needed
import {
    urlsToAddClientToken,
    urlsToSkipAuthorizationToken,
} from '../models/oauth-detail';
import { throwError } from 'rxjs';

const blockedUrls = [
    'https://blockly-demo.appspot.com/static/media/sprites.png',
];
export const tokenInterceptor: HttpInterceptorFn = (request, next) => {
    const authService = inject(AuthService); // Inject AuthService dynamically
    const url = request.url;

    if (blockedUrls.some((url) => request.url.includes(url))) {
        console.warn(`Blocked request to: ${request.url}`);
        return throwError(() => new Error('This API is blocked'));
    }

    // Check if the request should not have any Authorization header
    if (urlsToSkipAuthorizationToken.some((prefix) => url.includes(prefix))) {
        return next(request); // Skip token addition
    }

    let token = authService.getUserAccessToken(); // Fetch default token

    // Use a different token for specific APIs
    if (urlsToAddClientToken.some((prefix) => url.includes(prefix))) {
        token = authService.getClientToken(); // Fetch alternate token
    }

    // Clone the request and add the Authorization header
    request = request.clone({
        setHeaders: {
            Authorization: `Bearer ${token}`,
        },
    });

    return next(request);
};
