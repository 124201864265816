import { Component, inject, signal } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { RegistrationStatusEnum } from '../../enums/registration-status';

@Component({
    selector: 'app-register-status-message',
    imports: [RouterLink],
    templateUrl: './register-status-message.component.html',
    styleUrl: './register-status-message.component.scss',
})
export class RegisterStatusMessageComponent {
    RegistrationStatusEnum = RegistrationStatusEnum;

    registerStatus = signal<RegistrationStatusEnum>(
        RegistrationStatusEnum.PROCESSING
    );

    userEmail = signal<string>('');

    private readonly activatedRoute = inject(ActivatedRoute);

    constructor() {
        const paramMap = this.activatedRoute.snapshot.queryParamMap;
        this.userEmail.set(paramMap.get('email') ?? '');

        this.registerStatus.set(
            paramMap.get('status') as RegistrationStatusEnum
        );
    }
}
