import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export const apiPrefixInterceptor: HttpInterceptorFn = (request, next) => {
    const url = request.url;

    // Ignore static asset requests
    if (isStaticAsset(url)) {
        return next(request);
    }

    // Add base URL for relative URLs (without 'http://' or 'https://')
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        request = addBaseUrl(request);
    }

    return next(request);
};

/**
 * Adds the appropriate base URL to the outgoing HTTP request.
 * Determines the base URL based on the request path and appends it to the request URL.
 *
 * @param {HttpRequest<any>} request - The original HTTP request.
 * @returns {HttpRequest<any>} - The modified HTTP request with the base URL.
 */
const addBaseUrl = (request: any) => {
    const baseUrl = getBaseUrlForRequest(request.url);
    const updatedUrl = `${baseUrl}${request.url}`;
    return request.clone({ url: updatedUrl });
};

/**
 * Determines the appropriate base URL based on the request URL.
 * If the request URL contains 'realms', the Keycloak URL is used. Otherwise, the default base URL is used.
 *
 * @param {string} url - The request URL.
 * @returns {string} - The base URL to be appended to the request URL.
 */
const getBaseUrlForRequest = (url: string): string => {
    if (url.includes('realms')) {
        return environment.keycloakUrl;
    }
    return environment.port
        ? `${environment.mffURL}:${environment.port}`
        : environment.mffURL;
};

/**
 * Determines if the request URL refers to a static asset.
 * Static assets like configuration files, contract templates, or i18n files are excluded from URL modification.
 *
 * @param {string} url - The request URL.
 * @returns {boolean} - Returns true if the request is for a static asset, otherwise false.
 */
const isStaticAsset = (url: string): boolean => {
    return [
        '/assets/default-block-xml',
        '/assets/data/contract-model-block-json',
        '/assets/data/actus-dictionary',
        '/assets/data/contract-templates',
        '/assets/data/INVESTOR',
        '/assets/data/PERSON',
        '/assets/data/graph',
        '/assets/data/schedule-data',
        '/assets/doc-files',
    ].some((prefix) => url.startsWith(prefix));
};
