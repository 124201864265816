import { Component, inject } from '@angular/core';
import { CustomizerSettingsService } from '../../customizer-settings/customizer-settings.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
    AbstractControl,
    FormBuilder,
    ReactiveFormsModule,
    UntypedFormGroup,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { AuthService } from '../../../core/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, switchMap, tap } from 'rxjs';
import { RegistrationStatusEnum } from '../enums/registration-status';

export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        // if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        //     // return if another validator has already found an error on the matchingControl
        //     return;
        // }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    };
}

@Component({
    selector: 'app-reset-password',
    imports: [
        RouterLink,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        ReactiveFormsModule,
    ],
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly authService = inject(AuthService);
    private readonly router = inject(Router);
    hide = true;
    private readonly userToken: string | null = null;

    public resetPasswordForm = this.fb.group(
        {
            newPassword: [
                '',
                [Validators.required, this.customPasswordValidator()],
            ],
            confirmNewPassword: ['', Validators.required],
        },
        {
            validators: MustMatch('newPassword', 'confirmNewPassword'),
        }
    );

    constructor(
        public themeService: CustomizerSettingsService,
        private readonly fb: FormBuilder,
        private readonly _snackBar: MatSnackBar
    ) {
        this.userToken =
            this.activatedRoute.snapshot.queryParamMap.get('token') ?? null;
    }

    /**
     * Custom validator function to enforce a 6-digit numeric password.
     * @returns A ValidatorFn that checks for exactly 6 digits and only numbers.
     */
    private customPasswordValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const password = control.value;
            if (!password) {
                return null;
            }

            const errors: { [key: string]: any } = {};

            // // Check if password is at least 8 characters long
            // if (password.length < 8) {
            //     errors.minLength = true;
            // }

            // // Check if password contains at least one digit
            // if (!/\d/.test(password)) {
            //     errors.containsNumber = true;
            // }

            // // Check if password contains at least one lowercase letter
            // if (!/[a-z]/.test(password)) {
            //     errors.containsLowercase = true;
            // }

            // // Check if password contains at least one uppercase letter
            // if (!/[A-Z]/.test(password)) {
            //     errors.containsUppercase = true;
            // }

            // // Check if password contains at least one special symbol
            // if (!/[@#$%^&*(),.?":{}|<>]/.test(password)) {
            //     errors.containsSymbol = true;
            // }

            // Return null if no errors, otherwise return the errors object
            return Object.keys(errors).length ? errors : null;
        };
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    toggleCardBorderTheme() {
        this.themeService.toggleCardBorderTheme();
    }

    toggleCardBorderRadiusTheme() {
        this.themeService.toggleCardBorderRadiusTheme();
    }

    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }

    recoverPasswordUsingKeycloak() {
        if (!this.resetPasswordForm.valid) {
            return;
        }

        const {
            exp: epochTime,
            sub: userId,
        }: {
            exp: number;
            sub: string;
        } = this.authService.parseJwt(this.userToken ?? '') || {};

        const isTokenExpired = new Date(epochTime * 1000) < new Date();

        if (isTokenExpired) {
            this._snackBar.open('Token expired!', 'Close');
            return;
        }

        this.authService
            .getClientTokenDetails()
            .pipe(
                tap((token) =>
                    this.authService.setClientToken(token.access_token)
                ),
                switchMap(() =>
                    this.authService.resetPasswordUsingKeycloak(
                        userId,
                        this.resetPasswordForm.get('newPassword')?.value
                    )
                ),
                catchError((error) => {
                    this._snackBar.open('Error resetting password', 'Close');
                    return error;
                })
            )
            .subscribe(() => {
                this._snackBar.open('Password reset successfully!', 'Close');
                this.router.navigate(
                    ['/authentication/forgot-password-recovery'],
                    {
                        queryParams: {
                            status: RegistrationStatusEnum.RECOVER_PASSWORD,
                        },
                    }
                );
            });
    }
}
