import { Component } from '@angular/core';
import { ListOfContractsComponent } from '../../components/list-of-contracts/list-of-contracts.component';
import { AccountService } from '../../services/account.service';
import { ContractDetails } from '../../models/contract-details';
import { Observable } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-contract-list-container',
    imports: [ListOfContractsComponent],
    templateUrl: './contract-list-container.component.html',
    styleUrl: './contract-list-container.component.scss',
})
export class ContractListContainerComponent {
    private readonly contractList$: Observable<ContractDetails[]> =
        this.accountService.getListOfContracts();

    public readonly signalContractList = toSignal(this.contractList$);

    constructor(private readonly accountService: AccountService) {}
}
