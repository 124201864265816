export interface OauthDetail {
    userID: string;
    ownerID?: string;
    rootUserID?: string;
    flag?: string;
    portNumber?: string;
    url?: string;
    userName: string;
}

export const urlsToAddClientToken: string[] = [
    '/credentials',
    '/users/',
    '/users?email=',
    '/send-verify-email',
    '/users',
    '/logout',
    '/validate-email-token',
    '/mark-email-verified',
];

export const urlsToSkipAuthorizationToken: string[] = [
    `/client/token`,
    `/assets/data/contract-model-block-json`,
    '/login',
    '/userValidation',
    '/file',
    '/upload',
    '/root/entity',
    'assets/i18n/',
];
