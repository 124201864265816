import { RouterLink } from '@angular/router';
import { Component, input, InputSignal, output } from '@angular/core';
import { ProductDetails } from '../../models/product-details';
import { MatCardModule } from '@angular/material/card';
import { CustomizerSettingsService } from '../../../customizer-settings/customizer-settings.service';
import { NgScrollbarModule } from 'ngx-scrollbar';

@Component({
    selector: 'app-list-of-products',
    imports: [MatCardModule, NgScrollbarModule],
    templateUrl: './list-of-products.component.html',
    styleUrl: './list-of-products.component.scss',
})
export class ListOfProductsComponent {
    productListInput: InputSignal<ProductDetails[] | undefined> = input();

    public productEmitter = output<ProductDetails>();

    constructor(public readonly themeService: CustomizerSettingsService) {}

    public onProductClick(product: ProductDetails): void {
        this.productEmitter.emit(product);
    }
}
