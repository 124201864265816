import { inject } from '@angular/core';
import {
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
} from '@angular/router';
import {
    patchState,
    signalStore,
    withHooks,
    withMethods,
    withState,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface AppState {
    loading: boolean;
    access_token: string;
}

const initialState: AppState = {
    loading: false,
    access_token: '',
};

// create a app state using signalState

export const AppStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((store) => ({
        startLoading(): void {
            patchState(store, { loading: true });
        },
        stopLoading(): void {
            patchState(store, () => ({ loading: false }));
        },
        setAccessToken(access_token: string): void {
            patchState(store, () => ({ access_token: access_token }));
        },
        //getAccessToken
        getAccessToken(): string {
            return store.access_token();
        },
        loadOnNavigation: rxMethod<any>(
            pipe(
                tap((event) => {
                    if (event instanceof NavigationStart) {
                        patchState(store, { loading: true });
                    } else if (
                        event instanceof NavigationEnd ||
                        event instanceof NavigationError
                    ) {
                        patchState(store, { loading: false });
                    }
                })
            )
        ),
    })),
    withHooks((store, router = inject(Router)) => {
        return {
            onInit() {
                store.loadOnNavigation(router.events);
            },
        };
    })
);
