@switch (forgotPasswordStatus()) {
@case(RegistrationStatusEnum.FORGOT_PASSWORD){
<div class="d-flex justify-content-center align-items-center h-100vh">
    <div class="reset-password text-center">
        <h3><i class="flaticon-envelope"></i></h3>
        <h4 class="mb-15">Please check your mail.</h4>
        <p class="ms-auto me-auto">We’ve sent an email with instructions on how to reset your password to
            <strong>{{userEmail()}}</strong>
        </p>
        <p class="ms-auto me-auto">
            <strong>Didn't receive the email?</strong>

            <a class="d-inline-block main-color fw-medium position-relative">Resend
                Email <i class="flaticon-telegram"></i></a>
        </p>
        <a mat-raised-button class="tagus mt-8 not-hover" routerLink="/authentication"><i
                class="flaticon-chevron-1"></i>Back to
            Login</a>
    </div>
</div>

}
@case(RegistrationStatusEnum.RECOVER_PASSWORD){
<div class="d-flex justify-content-center align-items-center h-100vh">
    <div class="reset-password text-center">
        <h3><i class="flaticon-envelope"></i></h3>
        <h4 class="mb-15">Password changed !</h4>
        <p class="ms-auto me-auto">Your password has been changed successfully.
        </p>
        <a mat-raised-button class="tagus mt-8 not-hover" routerLink="/authentication"><i
                class="flaticon-chevron-1"></i>Back to
            Login</a>
    </div>
</div>
}
}