@switch (registerStatus()) {
@case(RegistrationStatusEnum.VERIFICATION_SENT){
<div class="d-flex justify-content-center align-items-center h-100vh">
    <div class="reset-password text-center">
        <h3><i class="flaticon-envelope"></i></h3>
        <h4 class="mb-15">Verify your email address to activate your account.</h4>
        <p class="ms-auto me-auto">An email with instructions to verify your email address has been sent to
            your address
            <strong>{{userEmail()}}</strong>
        </p>
        <p class="ms-auto me-auto">
            <strong>Didn't receive the email?</strong>

            <a class="d-inline-block main-color fw-medium position-relative">Resend
                Email <i class="flaticon-telegram"></i></a>
        </p>
        <a mat-raised-button class="tagus mt-8 not-hover" routerLink="/authentication"><i
                class="flaticon-chevron-1"></i>Back to
            Login</a>
    </div>
</div>
}
@case(RegistrationStatusEnum.UNDER_REVIEW){
<div class="d-flex justify-content-center align-items-center h-100vh">
    <div class="reset-password text-center">
        <h3><i class="flaticon-envelope"></i></h3>
        <h4 class="mb-15">Your account has been successfully created and is currently under
            review.</h4>
        <p class="ms-auto me-auto">Our administrator will be in touch with you soon.

        </p>
        <a mat-raised-button class="tagus mt-8 not-hover" routerLink="/authentication"><i
                class="flaticon-chevron-1"></i>Back to
            Login</a>
    </div>
</div>
}
@case(RegistrationStatusEnum.VERIFIED){
<div class="d-flex justify-content-center align-items-center h-100vh">
    <div class="reset-password text-center">
        <h3><i class="flaticon-envelope"></i></h3>
        <h4 class="mb-15">Your registration is successful</h4>

        <a mat-raised-button class="tagus mt-8 not-hover" routerLink="/authentication"><i
                class="flaticon-chevron-1"></i>Back to
            Login</a>
    </div>
</div>
}
}