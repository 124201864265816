import {
    ApplicationConfig,
    inject,
    provideAppInitializer,
    provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import { routes } from './app.routes';
import {
    provideClientHydration,
    withEventReplay,
    withIncrementalHydration,
} from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
    provideHttpClient,
    withFetch,
    withInterceptors,
} from '@angular/common/http';
import { apiPrefixInterceptor } from './core/interceptors/api-prefix.interceptor';
import { tokenInterceptor } from './core/interceptors/token.interceptor';
import { AppInitService } from './core/services/app-init.service';

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes, withComponentInputBinding()),
        provideClientHydration(withIncrementalHydration(), withEventReplay()),
        provideAnimationsAsync(),
        provideHttpClient(
            withInterceptors([apiPrefixInterceptor, tokenInterceptor]),
            withFetch()
        ),
        AppInitService,

        provideAppInitializer(() => {
            const appInitService = inject(AppInitService);
            return appInitService.loadAppConfig();
        }),
    ],
};
