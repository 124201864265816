<div class="row">
    <div class="col-12">
        <app-account-status></app-account-status>
    </div>
    <div class="col-12 col-md-5">
        <app-contract-list-container></app-contract-list-container>
    </div>
    <div class="col-12 col-md-7">
        <app-product-list-container></app-product-list-container>
        <!-- <app-contract-creation-steps></app-contract-creation-steps> -->

    </div>
</div>
<!-- <div class="row">
    <app-contract-list-container></app-contract-list-container>
</div> -->