import { Component } from '@angular/core';
import { ProductListContainerComponent } from '../../containers/product-list-container/product-list-container.component';
import { AccountService } from '../../services/account.service';

import { ContractListContainerComponent } from '../../containers/contract-list-container/contract-list-container.component';
import { AccountStatusComponent } from '../account-status/account-status.component';

@Component({
    selector: 'app-account-page',
    imports: [
        ProductListContainerComponent,
        ContractListContainerComponent,
        AccountStatusComponent,
    ],
    providers: [AccountService],
    templateUrl: './account-page.component.html',
    styleUrl: './account-page.component.scss',
})
export class AccountPageComponent {}
