import { Component, inject, signal } from '@angular/core';
import { CustomizerSettingsService } from '../../customizer-settings/customizer-settings.service';
import { Router, RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { AuthService } from '../../../core/services/auth.service';
import {
    catchError,
    filter,
    map,
    mergeMap,
    of,
    switchMap,
    tap,
    throwError,
} from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { KeycloakUser } from '../../../core/models/keycloak-user';
import { NgIf } from '@angular/common';
import { RegistrationStatusEnum } from '../enums/registration-status';
import { LoadingComponent } from '../../../shared/components/loaing/loading.component';

@Component({
    selector: 'app-register',
    imports: [
        RouterLink,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        ReactiveFormsModule,
        NgIf,
        LoadingComponent,
    ],
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
    private readonly authService = inject(AuthService);
    registerForm: FormGroup;
    emailChecked: boolean | null = false;

    emailExists: boolean = true;

    hide = true;

    keycloakUser: KeycloakUser[] = [];

    loading = signal<boolean>(false);

    constructor(
        public themeService: CustomizerSettingsService,
        private readonly fb: FormBuilder,
        private readonly _snackBar: MatSnackBar,
        private readonly router: Router
    ) {
        this.registerForm = this.fb.group(
            {
                email: ['', [Validators.required, Validators.email]],
                password: ['', [Validators.required, Validators.minLength(6)]],
                confirmPassword: ['', [Validators.required]],
            },
            { validator: this.matchPasswords }
        );
    }

    matchPasswords(group: FormGroup) {
        const password = group.get('password')?.value;
        const confirmPassword = group.get('confirmPassword')?.value;
        return password === confirmPassword ? null : { notMatching: true };
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    toggleCardBorderTheme() {
        this.themeService.toggleCardBorderTheme();
    }

    toggleCardBorderRadiusTheme() {
        this.themeService.toggleCardBorderRadiusTheme();
    }

    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }

    onContinue() {
        this.loading.set(true);
        if (!this.emailChecked) {
            const email = this.registerForm.get('email')?.value;

            this.checkEmail(email);
        } else {
            this.registerAccount();
        }
    }

    checkEmail(email: string) {
        this.authService
            .getClientTokenDetails()
            .pipe(
                tap((token) =>
                    this.authService.setClientToken(token.access_token)
                ),
                switchMap(() => this.authService.getUserByEmail(email)),
                mergeMap((user) => {
                    this.keycloakUser = user;
                    if (user?.length > 0) {
                        if (user[0].emailVerified) {
                            return throwError(
                                () => new Error('User already exists')
                            ); // Properly emit error
                        }
                        return of(user); // Wrap user in an observable to continue the stream
                    }
                    this.emailChecked = true;
                    return of([]); // Wrap user in an observable to continue the stream
                }),
                filter((user) => {
                    this.loading.set(false);
                    return user?.length > 0;
                }), // Only proceed if user is non-empty
                switchMap((user) =>
                    this.authService.sendEmailVerificationToUser(user[0].id)
                ),
                catchError((error: Error) => {
                    this.loading.set(false);
                    this._snackBar.open(error.message, 'Dismiss');
                    return throwError(() => error);
                })
            )
            .subscribe((user) => {
                this.loading.set(false);
                console.log(this.loading());
                if (this.emailChecked) {
                    this.registerForm.get('password')?.enable();
                    this.registerForm.get('confirmPassword')?.enable();
                } else {
                    this.router.navigate(['/authentication/register-status'], {
                        queryParams: {
                            email: this.registerForm.get('email')?.value,
                            userId: user?.[0]?.id,
                            status: RegistrationStatusEnum.VERIFICATION_SENT,
                        },
                    });
                }
            });
    }

    registerAccount() {
        this.authService
            .registerAccountUsingKeycloak(this.registerForm)
            .pipe(
                switchMap(() =>
                    this.authService.getUserByEmail(
                        this.registerForm.get('email')?.value
                    )
                ),
                switchMap((users) =>
                    this.authService
                        .sendEmailVerificationToUser(users[0].id)
                        .pipe(map(() => users))
                ),
                catchError((error: Error) => {
                    this.loading.set(false);
                    const userDisableMessage = 'User is disabled';

                    if (error.message === userDisableMessage) {
                        this.router.navigate(
                            ['/authentication/register-status'],
                            {
                                queryParams: {
                                    email: this.registerForm.get('email')
                                        ?.value,
                                    status: RegistrationStatusEnum.UNDER_REVIEW,
                                },
                            }
                        );
                    }
                    return throwError(() => error);
                })
            )
            .subscribe((user) => {
                // if (res.status === 201) {
                this.router.navigate(['/authentication/register-status'], {
                    queryParams: {
                        email: this.registerForm.get('email')?.value,
                        userId: user[0].id,
                        status: RegistrationStatusEnum.VERIFICATION_SENT,
                    },
                });
                // } else {
                // this._snackBar.open('Registration failed', 'Close');
                // }
            });
    }
}
