import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AccountStore } from './store/account-store';

@Component({
    selector: 'app-accounts',
    imports: [RouterOutlet],
    providers: [AccountStore],
    templateUrl: './accounts.component.html',
    styleUrl: './accounts.component.scss',
})
export class AccountsComponent {}
