import { Component, input } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-loading',
    imports: [MatProgressSpinner],
    template: `
        @if(loading()){
        <mat-progress-spinner [diameter]="size()" mode="indeterminate">
        </mat-progress-spinner>
        @if(text()){
        <span class="gray-color">{{ text() }}</span>
        } } @else {
        <ng-content />
        }
    `,
    styles: `
  :host{
    position: relative;
  }
  `,
})
export class LoadingComponent {
    loading = input<boolean | undefined>(false);
    text = input<string>('');
    size = input<number>(0);
}
