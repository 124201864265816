import { Component, effect, inject, input, signal } from '@angular/core';
import { LoginComponent, Provider } from '../../login/login.component';
import { LoginCredential } from '../../models/login-credential';
import { AuthService } from '../../../../core/services/auth.service';
import { firstValueFrom, map, switchMap, take, tap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStore } from '../../../../store/app.store';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-login-controller',
    imports: [LoginComponent],
    templateUrl: './login-controller.component.html',
    styleUrl: './login-controller.component.scss',
})
export class LoginControllerComponent {
    public readonly appStore = inject(AppStore);
    private readonly authService = inject(AuthService);
    private readonly router = inject(Router);
    private readonly activatedRoute = inject(ActivatedRoute);

    public error = signal<string | null>(null);
    public code = signal<string | null>(null);
    public token = signal<string | null>(null);

    public provider = signal<Provider>('none');

    public readonly loginValue = input();
    public googleLoading: boolean = false;
    public microsoftLoading: boolean = false;

    constructor(private readonly _snackBar: MatSnackBar) {
        // Extract query params and set signals
        this.activatedRoute.queryParams.subscribe((params) => {
            this.code.set(params['code'] || null);
            this.token.set(params['token'] || null);
        });

        // Effect to react to code changes
        effect(() => {
            const codeValue = this.code();
            const tokenValue = this.token();

            if (codeValue) {
                this.postLoginWithProvider(codeValue);
            }
        });
    }

    loginWithCredentials(loginCredential: LoginCredential) {
        this.appStore.startLoading();

        this.authService
            .loginWithCredentials(loginCredential)
            .pipe(
                tap((response) => {
                    this.authService.setUserAccessToken(response.access_token);
                })
            )
            .subscribe({
                next: (response) => {
                    Promise.all([
                        firstValueFrom(
                            this.authService.getOauthDetails().pipe(take(1))
                        ),
                        firstValueFrom(this.authService.getSidebarMenuRole()),
                    ])
                        .then(([oauthDetail, sidebarMenu]) => {
                            this.authService.setOauthDetail(oauthDetail);
                            this.authService.setSidebarMenus({
                                menu: sidebarMenu.menuList,
                            });
                            this.appStore.stopLoading();
                            this.appStore.setAccessToken(response.access_token);

                            this.router.navigate(['/accounts']);
                        })
                        .catch((error) => {
                            this.appStore.stopLoading();
                        });
                },
                error: (error: string) => {
                    this._snackBar.open(error, 'Close');
                    this.appStore.stopLoading();
                },
            });
    }

    getOauthDetails() {
        this.authService.getOauthDetails();
    }

    async loginWithSocial(provider: Provider): Promise<void> {
        try {
            await this.authService.loginWithSocial(provider);
        } catch (error) {
            console.error('Error during login process:', error);
        }
    }

    postLoginWithProvider(code: string): void {
        // this.appStore.startLoading();

        if (sessionStorage.getItem('isReturningFromProvider') === 'true') {
            this.provider.set(sessionStorage.getItem('provider') as Provider);
            sessionStorage.removeItem('isReturningFromProvider');
            sessionStorage.removeItem('provider');
        }
        this.authService
            .fetchTokenFromCode(code)
            .pipe(
                tap((token) => {
                    this.authService.setUserAccessToken(token.access_token);
                }),
                switchMap((userToken) =>
                    this.authService.getOauthDetails().pipe(
                        map((oauthDetails) => ({ userToken, oauthDetails })) // Combine token and oauthDetails
                    )
                )
            )
            .subscribe({
                next: ({ userToken, oauthDetails }) => {
                    const userId = this.authService.parseJwt(
                        userToken?.id_token ?? ''
                    )?.sub;

                    this.authService
                        .getClientTokenDetails()
                        .subscribe((token) => {
                            this.authService.setClientToken(token.access_token);

                            this.authService
                                .getUserCredentialsByUserId(userId)
                                .pipe(take(1))
                                .subscribe({
                                    next: async (credentials) => {
                                        for (const item of credentials) {
                                            if (item.type === 'password') {
                                                try {
                                                    const sidebarMenu =
                                                        await firstValueFrom(
                                                            this.authService
                                                                .getSidebarMenuRole()
                                                                .pipe(take(1))
                                                        );

                                                    this.authService.setOauthDetail(
                                                        oauthDetails
                                                    );
                                                    this.authService.setSidebarMenus(
                                                        {
                                                            menu: sidebarMenu.menuList,
                                                        }
                                                    );
                                                    this.appStore.stopLoading();
                                                    this.appStore.setAccessToken(
                                                        userToken.access_token
                                                    );

                                                    sessionStorage.removeItem(
                                                        'provider'
                                                    ); // once sso login remove provider
                                                    this.router.navigate([
                                                        '/accounts',
                                                    ]);
                                                    return; // Stop further execution after the first navigation
                                                } catch (e) {
                                                    this.appStore.stopLoading();
                                                }
                                            }
                                        }

                                        const queryParams = {
                                            userId: oauthDetails.userID,
                                        };
                                        this.router.navigate(
                                            ['/authentication/forgot-password'],
                                            {
                                                queryParams,
                                            }
                                        );
                                    },
                                    error: (err) => {
                                        this.appStore.stopLoading();
                                    },
                                });
                        });
                },
                error: (error) => {
                    this._snackBar.open(error, 'Close');
                    this.appStore.stopLoading();
                },
            });
    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action);
    }
}
