export const commonEnvironment = {
    protocol: 'https://',

    // MFF variables
    port: (window as Window)?.['env']?.baseUrlPort,

    // serverUrl:
    //     (window as Window)?.['env']?.serverUrl || 'http://localhost:4500',

    serverUrl: (window as Window)?.['env']?.serverUrl || 'demo.solitx.io',

    baseApiUrl:
        (window as Window)?.['env']?.baseApiUrl ||
        'sandbox.mobilefirstfinance.com',

    rootUser: (window as Window)?.['env']?.rootUser || 'TEST',

    redirectLoginUri: '', // To be set later
    redirectUri: '', // To be set later
    mffURL: '', // To be set later

    // Keycloak variables
    clientId: (window as Window)?.['env']?.keycloakClientId || 'mff',

    clientSecret:
        (window as Window)?.['env']?.keycloakClientSecret ||
        'eKmGhZhSQuiolxfvTHyw7J2SsKSbUgoN',

    realm: (window as Window)?.['env']?.keycloakRealm || 'Sandbox',

    keycloakBaseApiUrl:
        (window as Window)?.['env']?.keycloakBaseApiUrl ||
        'keycloak.mobilefirstfinance.com',

    keycloakPort: (window as Window)?.['env']?.keycloakPort || '8443',

    keycloakUrl: '', // To be set later

    // Chat base environment variables

    chatBasePublicKey:
        (window as Window)?.['env']?.chatBasePublicKey ||
        'lZRdQJ7AmloWo493xH71d',

    chatBaseDomain:
        (window as Window)?.['env']?.chatBaseDomain || 'www.chatbase.co',

    chatBase_min_JS:
        (window as Window)?.['env']?.chatBase_min_JS ||
        'https://www.chatbase.co/embed.min.js',
};

// Setting dynamic URLs using template strings
commonEnvironment[
    'redirectUri'
] = `${commonEnvironment.protocol}${commonEnvironment.serverUrl}/pages/register-successfull-page`;

commonEnvironment[
    'redirectLoginUri'
] = `${commonEnvironment.protocol}${commonEnvironment.serverUrl}/authentication`;

commonEnvironment[
    'keycloakUrl'
] = `${commonEnvironment.protocol}${commonEnvironment.keycloakBaseApiUrl}:${commonEnvironment.keycloakPort}`;
commonEnvironment[
    'mffURL'
] = `${commonEnvironment.protocol}${commonEnvironment.baseApiUrl}`;
