import { Component, inject } from '@angular/core';
import { AccountService } from '../../services/account.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { ProductDetails } from '../../models/product-details';
import { Observable } from 'rxjs';
import { ListOfProductsComponent } from '../../components/list-of-products/list-of-products.component';
import { NavigationExtras, Router } from '@angular/router';
import { AccountStore } from '../../store/account-store';

@Component({
    selector: 'app-product-list-container',
    imports: [ListOfProductsComponent],
    templateUrl: './product-list-container.component.html',
    styleUrl: './product-list-container.component.scss',
})
export class ProductListContainerComponent {
    private readonly router = inject(Router);
    private readonly store = inject(AccountStore);

    private readonly productList$: Observable<ProductDetails[]> =
        this.accountService.getListOfProducts();

    public readonly signalProductList = toSignal(this.productList$);

    constructor(private readonly accountService: AccountService) {}

    onProductClick(product: ProductDetails): void {
        this.store.updateSelectedProduct(product);
        const extras: NavigationExtras = {
            state: product,
        };
        this.router.navigate(['/accounts/create-contract'], extras);
    }
}
