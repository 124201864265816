import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { firstValueFrom, map } from 'rxjs';
import { RootEntity } from '../models/root-entity';
import { APIResponse } from '../models/api-response';

@Injectable({
    providedIn: 'root',
})
export class AppInitService {
    // Define a signal to store the API data
    private readonly _appConfig = signal<RootEntity | null>(null);

    constructor(private readonly http: HttpClient) {}

    // Fetch API data and update the signal
    async loadAppConfig(): Promise<RootEntity | null> {
        const mffUrl = `https://dev.mobilefirstfinance.com`;

        try {
            const data = await firstValueFrom(
                this.http
                    .get<APIResponse>(`${mffUrl}:5455/root/entity`, {
                        observe: 'body',
                    })
                    .pipe(
                        map((response) => {
                            return response?.data['rootData'] as RootEntity;
                        })
                    )
            );
            this._appConfig.set(data);
            return data;
        } catch (error) {
            this._appConfig.set(null);
            return null;
        }

        // return {
        //     rootConfigId: 1,
        //     url: '',
        //     themeColor: '',
        //     logoLink: '',
        //     name: '',
        //     font: '',
        //     port: '',
        //     twoFactor: false,
        //     rootuserId: '',
        //     entityName: '',
        // };
    }

    // Expose the signal to other components
    get appConfig() {
        return this._appConfig;
    }
}
