import {
    AfterViewInit,
    Component,
    input,
    InputSignal,
    ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ContractDetails } from '../../models/contract-details';

@Component({
    selector: 'app-list-of-contracts',
    imports: [
        MatCardModule,
        MatMenuModule,
        MatButtonModule,
        MatPaginatorModule,
        MatTableModule,
    ],
    templateUrl: './list-of-contracts.component.html',
    styleUrl: './list-of-contracts.component.scss',
})
export class ListOfContractsComponent implements AfterViewInit {
    contractList: InputSignal<ContractDetails[] | undefined> = input();

    displayedColumns: string[] = [
        'contractId',
        'productName',
        'balance',
        'status',
    ];

    dataSource = new MatTableDataSource<ContractDetails>([]);

    @ViewChild(MatPaginator) paginator: MatPaginator;

    ngAfterViewInit() {
        this.dataSource = new MatTableDataSource<ContractDetails>(
            this.contractList() ?? []
        );
        this.dataSource.paginator = this.paginator;
    }

    active = true;
    inactive = true;
}
