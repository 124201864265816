<mat-card class="mb-25 tagus-card">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Contract List</h5>
        <form class="search-box position-relative me-15 ">
            <input type="text" class="input-search d-block" placeholder="Search here..">
            <button type="submit"><i class="ri-search-line"></i></button>
        </form>
    </mat-card-header>
    <mat-card-content>
        <div class="teamMembersListTable tagus-mat-table mat-elevation-z8">
            <table mat-table [dataSource]="dataSource">

                <!-- User Column -->
                <ng-container matColumnDef="contractId">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Contract ID</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        <div class="d-flex align-items-center user-info">

                            <div class="title">
                                <span class="muted-color fw-normal d-block">{{element.contractId}}</span>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="productName">
                    <th mat-header-cell *matHeaderCellDef>Product Name</th>
                    <td mat-cell *matCellDef="let element"><a
                            href="mailto:{{element.email}}">{{element.productName}}</a></td>
                </ng-container>

                <!-- Role Column -->
                <ng-container matColumnDef="balance">
                    <th mat-header-cell *matHeaderCellDef>Balance</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="role-info position-relative">
                            {{element.balance}}
                        </div>
                    </td>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element">
                        @if(element.status){
                        <span class="badge">{{element.status}}</span>
                        }
                        <!-- @if(element.status.inactive){
                        <span class="badge inactive">{{element.status}}</span>
                        } -->
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
            <mat-paginator [pageSizeOptions]="[4, 8, 12]" showFirstLastButtons>
            </mat-paginator>
        </div>
    </mat-card-content>
</mat-card>