import { Component, inject, signal } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { RegistrationStatusEnum } from '../enums/registration-status';

@Component({
    selector: 'app-forgot-password-recover-info-page',
    imports: [RouterLink],
    templateUrl: './forgot-password-recover-info-page.component.html',
    styleUrl: './forgot-password-recover-info-page.component.scss',
})
export class ForgotPasswordRecoverInfoPageComponent {
    RegistrationStatusEnum = RegistrationStatusEnum;
    userEmail = signal<string>('');
    forgotPasswordStatus = signal<RegistrationStatusEnum>(
        RegistrationStatusEnum.PROCESSING
    ); //

    private readonly activatedRoute = inject(ActivatedRoute);

    constructor() {
        const paramMap = this.activatedRoute.snapshot.queryParamMap;
        this.userEmail.set(paramMap.get('email') ?? '');
        this.forgotPasswordStatus.set(
            paramMap.get('status') as RegistrationStatusEnum
        );
    }
}
