export enum RegistrationStatusEnum {
    VERIFICATION_SENT = 'verification_sent',
    UNDER_REVIEW = 'under_review',
    VERIFIED = 'verified',
    ACCOUNT_CREATED = 'account_created',
    PROCESSING = 'processing',
    REJECTED = 'rejected',
    FORGOT_PASSWORD = 'forgot_password',
    RECOVER_PASSWORD = 'recover_password',
}
