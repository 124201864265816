<header class="header-area bg-white border-bottom-radius box-shadow"
    [class.component-dark-theme]="themeService.isDark()" [class.right-sidebar]="themeService.isRightSidebar()"
    [class.hide-sidebar]="themeService.isHideSidebar()" [class.dark-header]="themeService.isHeaderDark()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.card-border-radius]="themeService.isCardBorderRadius()" [class.rtl-enabled]="themeService.isRTLEnabled()"
    [ngClass]="{'active': isToggled}">
    <div class="d-md-flex align-items-center justify-content-space-between">
        <div class="header-left-side d-flex align-items-center">
            <div class="burger-menu" (click)="toggle()" [ngClass]="{'active': isToggled}">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </div>
            <!-- <button mat-icon-button [matMenuTriggerFor]="appsMenu"
                class="menu-btn rounded-circle p-0 border-none position-relative">
                <i class="flaticon-menu-1"></i>
            </button>
            <mat-menu class="menu-dropdown" #appsMenu="matMenu" xPosition="after">
                <div class="menu-header d-flex align-items-center justify-content-space-between">
                    <h5 class="m-0">Web Apps</h5>
                    <button type="button" class="bg-transparent p-0 border-none">Edit</button>
                </div>
                <div class="menu-body">
                    <div class="d-flex flex-wrap align-items-center">
                        <a href="#" class="dropdown-item" target="_blank">
                            <img src="img/web-apps/account.png" alt="image">
                            <span class="d-block mb-0 fw-medium">Account</span>
                        </a>
                        <a href="#" class="dropdown-item" target="_blank">
                            <img src="img/web-apps/google.png" alt="image">
                            <span class="d-block mb-0 fw-medium">Search</span>
                        </a>
                        <a href="#" class="dropdown-item" target="_blank">
                            <img src="img/web-apps/map.png" alt="image">
                            <span class="d-block mb-0 fw-medium">Maps</span>
                        </a>
                        <a href="#" class="dropdown-item" target="_blank">
                            <img src="img/web-apps/youtube.png" alt="image">
                            <span class="d-block mb-0 fw-medium">YouTube</span>
                        </a>
                        <a href="#" class="dropdown-item" target="_blank">
                            <img src="img/web-apps/playstore.png" alt="image">
                            <span class="d-block mb-0 fw-medium">Play</span>
                        </a>
                        <a href="#" class="dropdown-item" target="_blank">
                            <img src="img/web-apps/gmail.png" alt="image">
                            <span class="d-block mb-0 fw-medium">Gmail</span>
                        </a>
                        <a href="#" class="dropdown-item" target="_blank">
                            <img src="img/web-apps/drive.png" alt="image">
                            <span class="d-block mb-0 fw-medium">Drive</span>
                        </a>
                        <a href="#" class="dropdown-item" target="_blank">
                            <img src="img/web-apps/calendar.png" alt="image">
                            <span class="d-block mb-0 fw-medium">Calendar</span>
                        </a>
                    </div>

                </div>
                <div class="menu-footer">
                    <a routerLink="/" class="fw-medium position-relative">View All <i class="flaticon-chevron"></i></a>
                </div>
            </mat-menu>
            <form class="search-box position-relative">
                <input type="text" class="input-search d-block" placeholder="Search here..">
                <button type="submit"><i class="ri-search-line"></i></button>
            </form> -->
        </div>
        <ul class="header-right-side d-flex align-items-center mt-0 mb-0">
            <li>
                <button mat-icon-button [matMenuTriggerFor]="languageMenu"
                    class="btn p-0 border-none rounded-circle position-relative">
                    <img src="img/flag/usa.png" alt="usa">
                </button>
                <mat-menu class="language-menu-dropdown" #languageMenu="matMenu" xPosition="after">
                    <ul class="m-0">
                        <li>
                            <button type="button" class="d-flex align-items-center border-none">
                                <img src="img/flag/usa.png" alt="usa">
                                English
                            </button>
                        </li>
                        <li>
                            <button type="button" class="d-fle align-items-center border-none">
                                <img src="img/flag/spain.png" alt="spain">
                                Spanish
                            </button>
                        </li>
                        <li>
                            <button type="button" class="d-fle align-items-center border-none">
                                <img src="img/flag/germany.png" alt="germany">
                                German
                            </button>
                        </li>
                    </ul>
                </mat-menu>
            </li>
            <!-- <li>
                <button mat-icon-button [matMenuTriggerFor]="emailMenu"
                    class="btn p-0 border-none rounded-circle position-relative">
                    <i class="flaticon-envelope"></i>
                </button>
                <mat-menu class="menu-dropdown" #emailMenu="matMenu" xPosition="before">
                    <div class="menu-header d-flex align-items-center justify-content-space-between">
                        <h5 class="m-0">Emails</h5>
                        <button type="button" class="bg-transparent p-0 border-none">Mark all as read</button>
                    </div>
                    <ul class="menu-body">
                        <li class="position-relative">
                            <div class="d-flex align-items-center">
                                <img src="img/user/user1.jpg" class="rounded-circle" alt="user">
                                <div class="title">
                                    <h6>Invoices have been paid</h6>
                                    <span><i class="flaticon-timer"></i> 14 mins ago</span>
                                </div>
                            </div>
                            <a routerLink="/email/read" class="link-btn"></a>
                            <button class="close-btn bg-transparent border-none p-0"><i
                                    class="flaticon-close"></i></button>
                        </li>
                        <li class="position-relative">
                            <div class="d-flex align-items-center">
                                <img src="img/user/user2.jpg" class="rounded-circle" alt="user">
                                <div class="title">
                                    <h6>Allow users to like products</h6>
                                    <span><i class="flaticon-timer"></i> 30 mins ago</span>
                                </div>
                            </div>
                            <a routerLink="/email/read" class="link-btn"></a>
                            <button class="close-btn bg-transparent border-none p-0"><i
                                    class="flaticon-close"></i></button>
                        </li>
                        <li class="position-relative">
                            <div class="d-flex align-items-center">
                                <img src="img/user/user4.jpg" class="rounded-circle" alt="user">
                                <div class="title">
                                    <h6>Your leave is approved</h6>
                                    <span><i class="flaticon-timer"></i> 2 hours ago</span>
                                </div>
                            </div>
                            <a routerLink="/email/read" class="link-btn"></a>
                            <button class="close-btn bg-transparent border-none p-0"><i
                                    class="flaticon-close"></i></button>
                        </li>
                        <li class="position-relative">
                            <div class="d-flex align-items-center">
                                <img src="img/user/user5.jpg" class="rounded-circle" alt="user">
                                <div class="title">
                                    <h6>Live visits on our site</h6>
                                    <span><i class="flaticon-timer"></i> 5 hours ago</span>
                                </div>
                            </div>
                            <a routerLink="/email/read" class="link-btn"></a>
                            <button class="close-btn bg-transparent border-none p-0"><i
                                    class="flaticon-close"></i></button>
                        </li>
                    </ul>
                    <div class="menu-footer">
                        <a routerLink="/email" class="fw-medium position-relative">View All <i
                                class="flaticon-chevron"></i></a>
                    </div>
                </mat-menu>
            </li>
            <li>
                <button mat-icon-button [matMenuTriggerFor]="notificationMenu"
                    class="btn p-0 border-none rounded-circle position-relative notification-btn">
                    <i class="flaticon-bell-2"></i>
                </button>
                <mat-menu class="menu-dropdown" #notificationMenu="matMenu" xPosition="before">
                    <div class="menu-header d-flex align-items-center justify-content-space-between">
                        <h5 class="m-0">Notifications</h5>
                        <button type="button" class="bg-transparent p-0 border-none">Mark all as read</button>
                    </div>
                    <ul class="menu-body">
                        <li class="position-relative">
                            <div class="d-flex">
                                <i class="flaticon-file-1 icon"></i>
                                <div class="title">
                                    <h6>8 Invoices have been paid</h6>
                                    <span><i class="flaticon-timer"></i> 14 mins ago</span>
                                </div>
                            </div>
                            <a routerLink="/notifications" class="link-btn"></a>
                            <button class="close-btn bg-transparent border-none p-0"><i
                                    class="flaticon-close"></i></button>
                        </li>
                        <li class="position-relative">
                            <div class="d-flex align-items-center">
                                <i class="flaticon-pdf-file icon"></i>
                                <div class="title">
                                    <h6>Allow users to like products</h6>
                                    <span><i class="flaticon-timer"></i> 30 mins ago</span>
                                </div>
                            </div>
                            <a routerLink="/notifications" class="link-btn"></a>
                            <button class="close-btn bg-transparent border-none p-0"><i
                                    class="flaticon-close"></i></button>
                        </li>
                        <li class="position-relative">
                            <div class="d-flex align-items-center">
                                <i class="flaticon-to-do-list icon"></i>
                                <div class="title">
                                    <h6>Live visits on our site</h6>
                                    <span><i class="flaticon-timer"></i> 5 hours ago</span>
                                </div>
                            </div>
                            <a routerLink="/notifications" class="link-btn"></a>
                            <button class="close-btn bg-transparent border-none p-0"><i
                                    class="flaticon-close"></i></button>
                        </li>
                    </ul>
                    <div class="menu-footer">
                        <a routerLink="/notifications" class="fw-medium position-relative">View All <i
                                class="flaticon-chevron"></i></a>
                    </div>
                </mat-menu>
            </li> -->

            <li>
                <button mat-icon-button class="btn p-0 border-none rounded-circle position-relative"
                    (click)="toggleTheme()">
                    @if(getToggleTheme()){
                    <mat-icon aria-hidden="false" class="material-symbols-outlined mt-5">light_mode</mat-icon>
                    }@else{
                    <mat-icon aria-hidden="false" class="material-symbols-outlined mt-5">dark_mode</mat-icon>
                    }
                </button>
            </li>

            <li class="d-none d-lg-block">
                <div class="date-btn main-color fw-semibold position-relative">
                    <i class="flaticon-calendar"></i> {{ currentDate | date: 'dd MMMM yyyy' }}
                </div>
            </li>
            <li>
                <button mat-icon-button [matMenuTriggerFor]="profileMenu"
                    class="profile-btn main-color border-none bg-transparent position-relative">
                    <!-- <img src="img/admin.jpg" class="rounded-circle" alt="admin"> -->

                    <i class="ri-user-3-line"></i>
                    Admin
                    <!-- <span class="fw-semibold"></span> -->
                </button>
                <mat-menu class="profile-menu-dropdown" #profileMenu="matMenu" xPosition="before">
                    <ul class="m-0">
                        <li>
                            <a>
                                <i class="ri-user-3-line"></i> {{authService.getUserDetails()?.userName ?? ''}}
                            </a>
                        </li>
                        <!--
                        <li>
                            <a routerLink="/account">
                                <i class="ri-settings-3-line"></i> Settings
                            </a>
                        </li> -->
                        <li>
                            <a (click)="logout()">
                                <!-- <i class="ri-shut-down-line"></i> -->
                                <span class="material-symbols-outlined">
                                    logout
                                </span>
                                Logout
                            </a>
                        </li>
                    </ul>
                </mat-menu>
            </li>
        </ul>
    </div>
</header>