<!-- <div class="row">
    @for(product of productListInput() ?? [];track product.productName){
    <div class="col w-100">
        <mat-card class="single-stats-box tagus-card mb-25">
            <div class="d-flex justify-content-space-between align-items-center">
                <div class="title">
                    <h3>{{product.productCategoryName}}</h3>
                    <span class="d-block gray-color">{{product?.productName}}</span>
                </div>
                <div class="icon">
                    <i class="flaticon-donut-chart"></i>
                </div>
            </div>

            <div class="d-md-flex meta border-none justify-content-space-between align-items-center mt-10">
                <ul class="list mb-0 d-md-flex mt-0 ps-0">
                    <li class="gray-color fw-semibold fs-13">
                        Contract Type
                        <div class="d-flex align-items-center">
                            <span class="d-block fw-semibold fs-10">{{product?.contractType}}</span>
                        </div>
                    </li>
                    <li class="gray-color fw-semibold fs-13">
                        Currency
                        <div class="d-flex align-items-center">
                            <span class="d-block fw-semibold fs-10">{{product?.currency}}</span>
                        </div>
                    </li>
                </ul>
                <div>
                    <a mat-raised-button
                        class="gray mdc-button mdc-button--raised mat-mdc-raised-button mat-unthemed mat-mdc-button-base"
                        routerLink="/">View Details </a>
                </div>
            </div>
        </mat-card>
    </div>
    }@empty{
    NO data
    }

</div> -->

<mat-card class="mb-25 tagus-card current-rate-box" [class.component-rtl-enabled]="themeService.isRTLEnabled()">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Product list</h5>
    </mat-card-header>
    <mat-card-content>
        <ng-scrollbar visibility="hover" style="height: 59vh;">
            <div class="row">
                <div class="col w-100">
                    @for(product of productListInput() ?? [];track product.productName){
                    <div tabindex="" class="box mb-10" (click)="onProductClick(product)">
                        <div class="title d-flex align-items-center justify-content-space-between">
                            <div class="d-flex align-items-center">
                                <h4 class="mb-0">{{product.productCategoryName}}</h4>
                            </div>
                            <span class="up"><i class="flaticon-trend"></i></span>
                        </div>
                        <span class="d-block mb-10 gray-color">{{product?.productName}}</span>
                        <div class="d-md-flex meta border-none justify-content-space-between align-items-center mt-10">
                            <ul class="list mb-0 d-md-flex mt-0 ps-0">
                                <li class="gray-color fw-semibold fs-13">
                                    Contract Type
                                    <div class="d-flex align-items-center">
                                        <span class="d-block fw-semibold fs-10">{{product?.contractType}}</span>
                                    </div>
                                </li>
                                <li class="gray-color fw-semibold fs-13">
                                    Currency
                                    <div class="d-flex align-items-center">
                                        <span class="d-block fw-semibold fs-10">{{product?.currency}}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <a class="link-btn main-hover-color"><i class="flaticon-chevron"></i></a>
                    </div>
                    }@empty{
                    NO data
                    }
                </div>
            </div>
        </ng-scrollbar>
    </mat-card-content>
</mat-card>