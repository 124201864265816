import { Component, input, output } from '@angular/core';
import { CustomizerSettingsService } from '../../customizer-settings/customizer-settings.service';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { NgIf } from '@angular/common';
import { LoginCredential } from '../models/login-credential';
import { LoadingComponent } from '../../../shared/components/loaing/loading.component';

export type Provider = 'google' | 'microsoft' | 'none';

@Component({
    selector: 'app-login',
    imports: [
        RouterLink,
        ReactiveFormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        NgIf,
        LoadingComponent,
    ],
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
    loginForm: FormGroup;
    hide = true;

    public loading = input<boolean>(false);

    public provider = input<Provider>();

    public isGoogleLoading: boolean = false;
    public isMicrosoftLoading: boolean = false;

    public readonly loginWithCredential = output<LoginCredential>();

    public readonly loginWithSocialProvider = output<Provider>();

    constructor(
        public themeService: CustomizerSettingsService,
        private readonly fb: FormBuilder
    ) {
        this.loginForm = this.fb.group({
            userName: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            rememberMe: [false],
        });
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    toggleCardBorderTheme() {
        this.themeService.toggleCardBorderTheme();
    }

    toggleCardBorderRadiusTheme() {
        this.themeService.toggleCardBorderRadiusTheme();
    }

    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }

    onSubmit() {
        if (this.loginForm.valid) {
            const { userName, password } = this.loginForm.value;
            this.loginWithCredential.emit({
                userName,
                password,
            } as LoginCredential);
            // Handle login logic here
        } else {
            this.loginForm.markAllAsTouched(); // Show validation errors
        }
    }

    loginWithSocial(event: MouseEvent, provider: Provider) {
        event.preventDefault();
        if (provider === 'google') {
            this.isGoogleLoading = true;
        } else {
            this.isMicrosoftLoading = true;
        }
        sessionStorage.setItem('isReturningFromProvider', 'true');
        sessionStorage.setItem('provider', provider);
        this.loginWithSocialProvider.emit(provider);
    }
}
