import { Routes } from '@angular/router';
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { AuthenticationComponent } from './components/authentication/authentication.component';

import { RegisterComponent } from './components/authentication/register/register.component';
import { ForgotPasswordComponent } from './components/authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/authentication/reset-password/reset-password.component';
import { SigninSignupComponent } from './components/authentication/signin-signup/signin-signup.component';
import { LogoutComponent } from './components/authentication/logout/logout.component';
import { ConfirmMailComponent } from './components/authentication/confirm-mail/confirm-mail.component';
import { LockScreenComponent } from './components/authentication/lock-screen/lock-screen.component';

import { AccountPageComponent } from './components/accounts/components/account-page/account-page.component';
import { AccountsComponent } from './components/accounts/accounts.component';
import { LoginControllerComponent } from './components/authentication/controller/login-controller/login-controller.component';

import { ForgotPasswordRecoverInfoPageComponent } from './components/authentication/forgot-password-recover-info-page/forgot-password-recover-info-page.component';
import { RegisterStatusMessageComponent } from './components/authentication/register/register-status-message/register-status-message.component';
import { AuthService } from './core/services/auth.service';
import { inject } from '@angular/core';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'authentication',
        pathMatch: 'full',
    },
    {
        path: 'accounts',
        component: AccountsComponent,
        canActivate: [() => inject(AuthService).isAuthenticated()],
        children: [
            {
                path: '',
                component: AccountPageComponent,
                // resolve: {
                //     products: listOfProductsResolver,
                // },
            },
            {
                path: 'create-contract',
                loadComponent: () =>
                    import(
                        './components/accounts/containers/create-contract-container/create-contract-container.component'
                    ).then((m) => m.CreateContractContainerComponent),
            },
        ],
    },
    {
        path: 'authentication',
        component: AuthenticationComponent,
        children: [
            { path: '', component: LoginControllerComponent },
            { path: 'register', component: RegisterComponent },
            { path: 'forgot-password', component: ForgotPasswordComponent },
            {
                path: 'forgot-password-recovery',
                component: ForgotPasswordRecoverInfoPageComponent,
            },
            {
                path: 'register-status',
                component: RegisterStatusMessageComponent,
            },
            { path: 'reset-password', component: ResetPasswordComponent },
            { path: 'signin-signup', component: SigninSignupComponent },
            { path: 'logout', component: LogoutComponent },
            { path: 'confirm-mail', component: ConfirmMailComponent },
            { path: 'lock-screen', component: LockScreenComponent },
        ],
    },

    { path: '**', component: NotFoundComponent }, // This line will remain down from the whole pages component list
];
