<!-- <div [ngClass]="{'blank-page': !!appStore.getAccessToken() === false || router.url === '/error-500' || router.url === '/authentication/reset-password' || router.url === '/authentication/forgot-password' || router.url === '/authentication' || router.url === '/authentication/register' || router.url === '/authentication/signin-signup' || router.url === '/authentication/logout' || router.url === '/authentication/confirm-mail' || router.url === '/authentication/lock-screen' || router.url === '/coming-soon'}" -->
<div class="container-xl">
    <div [ngClass]="{'blank-page': !appStore.getAccessToken() === true}"
        [class.card-borderd-theme]="themeService.isCardBorder()"
        [class.card-border-radius]="themeService.isCardBorderRadius()" [class.rtl-enabled]="themeService.isRTLEnabled()"
        class="app-container">

        @if(appStore.getAccessToken()){
        <app-sidebar />
        }

        <div class="main-content d-flex flex-column" [ngClass]="{'active': isToggled}"
            [class.right-sidebar]="themeService.isRightSidebar()" [class.hide-sidebar]="themeService.isHideSidebar()">

            @if(appStore.getAccessToken()){
            <app-header />
            }
            <router-outlet />
            <div class="flex-grow-1"></div>
            @if(appStore.getAccessToken()){
            <app-footer />
            }

        </div>
        <!-- @if(appStore.getAccessToken()){ -->
        <!-- <app-customizer-settings /> -->
        <!-- } -->
    </div>
</div>