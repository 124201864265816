import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { LoginResponse } from '../../components/authentication/models/login-response';
import { catchError, map, Observable, switchMap, throwError, tap } from 'rxjs';
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
    HttpParams,
} from '@angular/common/http';
import { OauthDetails } from '../../components/authentication/models/oauth-details';
import { LoginCredential } from '../../components/authentication/models/login-credential';
import { generateCodeChallenge, getRandomString } from '../utility/utility';
import { OauthDetail } from '../models/oauth-detail';
import { Menu, SidebarMenu } from '../models/sidebar-menu';
import { APIResponse } from '../models/api-response';
import { environment } from '../../../environments/environment.development';
import { Router } from '@angular/router';
import { ParseJwt } from '../../components/authentication/models/parse-jwt';
import { AppStore } from '../../store/app.store';
import { KeycloakUser } from '../models/keycloak-user';
import { Provider } from '../../components/authentication/login/login.component';
// import { PostLoginNavigationContextService } from '../models/action-handler';

// Sidebar route metadata
export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    badge?: string;
    badgeClass?: string;
    isExternalLink: boolean;
    submenu: RouteInfo[];
}

export const ROUTES: RouteInfo[] = [
    {
        path: '/client/list-of-clients',
        title: 'Clients',
        icon: 'ft-users',
        class: '',
        isExternalLink: false,
        submenu: [],
    },
    {
        path: '/account/account-list',
        title: 'Account',
        icon: 'ft-briefcase',
        class: '',
        isExternalLink: false,
        submenu: [],
    },
];

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private readonly router = inject(Router);
    private readonly httpClient = inject(HttpClient);
    private readonly appStore = inject(AppStore);

    private readonly codeVerifier: WritableSignal<string> = signal('');
    private readonly userDetails = signal<OauthDetail | null>(null);

    private readonly accessTokenKey = 'access_token';
    private readonly refreshTokenKey = 'refresh_token';
    private readonly accessTokenExpiryKey = 'access_token_expiry';
    private readonly refreshTokenExpiryKey = 'refresh_token_expiry';

    private readonly routesTitleSet = new Set(
        ROUTES.map((route) => route.title)
    );

    private readonly oauthDetail: WritableSignal<OauthDetail> = signal({
        userID: '',
        userName: '',
    });

    private readonly sidebarMenus: WritableSignal<SidebarMenu> = signal({
        menu: [],
    });

    private accessToken: string;
    private clientToken: string;

    constructor() {}

    isAuthenticated() {
        if (this.getUserAccessToken()) {
            return true;
        }
        this.router.navigate(['/authentication']);
        return false;
    }

    getUserDetails(): OauthDetail | null {
        return this.userDetails();
    }

    // Save tokens and expiry times in local storage
    saveAccessToken(tokenData: LoginResponse) {
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        const accessTokenExpiryTime = currentTime + (tokenData.expires_in ?? 0);
        sessionStorage.setItem(this.accessTokenKey, tokenData.access_token);
        sessionStorage.setItem(
            this.accessTokenExpiryKey,
            accessTokenExpiryTime.toString()
        );

        if (tokenData.refresh_token) {
            this.setUserAccessToken(tokenData.access_token);
            const refreshTokenExpiryTime =
                currentTime + (tokenData.refresh_expires_in ?? 0);
            sessionStorage.setItem(
                this.refreshTokenKey,
                tokenData.refresh_token
            );
            sessionStorage.setItem(
                this.refreshTokenExpiryKey,
                refreshTokenExpiryTime.toString()
            );
        }
    }

    setCodeVerify(code: string) {
        this.codeVerifier.set(code);
    }

    getCodeVerify(): string {
        return this.codeVerifier();
    }

    setClientToken(clientToken: string) {
        this.clientToken = clientToken;
    }

    getClientToken(): string {
        return this.clientToken;
    }

    setUserAccessToken(token: string) {
        this.accessToken = token;
    }

    getUserAccessToken(): string {
        return this.accessToken;
    }

    setOauthDetail(oauthDetail: OauthDetail) {
        this.oauthDetail.set(oauthDetail);
    }

    getOauthDetail(): OauthDetail {
        return this.oauthDetail();
    }

    setSidebarMenus(menus: SidebarMenu): void {
        this.sidebarMenus.set(menus);
    }

    getSidebarMenus(): SidebarMenu {
        return this.sidebarMenus();
    }

    loginWithCredentials(
        loginCredential: LoginCredential,
        observe?: 'body',
        responseType?: 'json',
        reportProgress?: boolean
    ): Observable<LoginResponse> {
        const loginUrl = `/LoginService/login`;
        return this.httpClient
            .post<LoginResponse>(`${loginUrl}`, loginCredential)
            .pipe(catchError(this.handleError));
    }

    getOauthDetails(
        observe?: 'body',
        responseType?: 'json',
        reportProgress?: boolean
    ): Observable<OauthDetails> {
        return this.httpClient
            .get<OauthDetails>(`/UserService/oauthDetails`, {
                observe,
                responseType,
            })
            .pipe(tap((res) => this.userDetails.set(res)));
    }

    getUserCredentialsByUserId(
        userId: string,
        observe?: 'body',
        responseType?: 'json',
        reportProgress: boolean = false
    ): Observable<any> {
        // Assuming it returns an array of credentials
        if (!userId) {
            return throwError(() => new Error('User ID is required'));
        }
        if (!environment?.realm) {
            return throwError(() => new Error('Realm is not configured'));
        }

        const credentialUrl = `/admin/realms/${environment.realm}/users/${userId}/credentials`;

        return this.httpClient.get<any>(credentialUrl, {
            observe, // Type-safe options
        });
    }

    getClientTokenDetails(): Observable<{
        access_token: string;
        [key: string]: string;
    }> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Skip-Authorization': 'true',
        });

        const tokenUrl = `/client/token`;
        return this.httpClient
            .get<{ access_token: string; [key: string]: string }>(
                `/LoginService${tokenUrl}`,
                {
                    headers,
                }
            )
            .pipe(
                map((res) => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    sendEmailVerificationToUser(userId: string) {
        return this.httpClient
            .put<any>(
                `/admin/realms/${environment?.realm}/users/${userId}/send-verify-email`,
                { redirect_uri: environment?.redirectUri }
            )
            .pipe(catchError(this.handleError));
    }

    registerAccountUsingKeycloak(form: any) {
        const body = {
            attributes: {
                userType: 'External',
            },
            email: form.get('email').value,
            username: form.get('email').value,
            enabled: true,
            requiredActions: ['VERIFY_EMAIL'],
            credentials: [
                {
                    type: 'password',
                    value: form.get('password').value,
                    temporary: false,
                },
            ],
        };

        return this.httpClient
            .post<any>(`/admin/realms/${environment.realm}/users`, body)
            .pipe(catchError(this.handleError));
    }

    sendResetPasswordLinkUsingKeycloak(userId: string): Observable<any> {
        const params = new HttpParams()
            .set('client_id', environment.clientId)
            .set('lifespan', 300)
            .set('redirect_uri', environment.redirectLoginUri);

        const payload = ['UPDATE_PASSWORD'];

        return this.httpClient
            .put(
                `/admin/realms/${environment.realm}/users/${userId}/execute-actions-email`,
                payload,
                {
                    params,
                }
            )
            .pipe(catchError(this.handleError));
    }

    getUserByEmail(email: string): Observable<KeycloakUser[]> {
        return this.httpClient
            .get<KeycloakUser[]>(
                `/admin/realms/${environment.realm}/users?email=${email}`
            )
            .pipe(catchError(this.handleError));
    }

    async loginWithSocial(provider: Provider): Promise<void> {
        try {
            const codeVerifier = getRandomString(128);
            const codeChallenge = await generateCodeChallenge(codeVerifier);
            const codeChallengeMethod = 'S256';

            // Store the PKCE code verifier for later use
            sessionStorage.setItem('code_verifier', codeVerifier);

            const authUrl = `${environment?.keycloakUrl}/realms/${
                environment?.realm
            }/protocol/openid-connect/auth?client_id=${
                environment?.clientId
            }&redirect_uri=${encodeURIComponent(
                environment?.redirectLoginUri
            )}&response_type=code&scope=openid profile email&response_mode=query&kc_idp_hint=${provider}&code_challenge=${codeChallenge}&code_challenge_method=${codeChallengeMethod}`;

            window.location.href = authUrl;
        } catch (error) {
            console.error('Error during login process:', error);
        }
    }

    getSidebarMenuRole(
        roleId?: string,
        observe?: 'body',
        responseType?: 'json',
        reportProgress?: boolean
    ): Observable<{ menuList: Menu[]; routeList: RouteInfo[] }> {
        const params = new HttpParams().set('roleId', roleId ?? '');
        return this.httpClient
            .get<APIResponse>(`/UserService/provideLinkMenuToRole`, {
                params,
                observe,
                responseType,
            })
            .pipe(
                map((response: APIResponse) => {
                    const menus = (response?.data as SidebarMenu)?.menu ?? [];
                    const menuList = menus.filter((menu) =>
                        this.routesTitleSet.has(menu.menuName)
                    );

                    const routeList = ROUTES.filter((route) =>
                        this.routesTitleSet.has(route.title)
                    );

                    return { menuList, routeList };
                }),
                catchError(this.handleError)
            );
    }

    fetchTokenFromCode(code: string): Observable<LoginResponse> {
        const codeVerifier = sessionStorage.getItem('code_verifier') as string;

        const tokenUrl = `/realms/${environment.realm}/protocol/openid-connect/token`;

        const params = new HttpParams()
            .set('client_id', environment.clientId)
            .set('grant_type', 'authorization_code')
            .set('redirect_uri', environment.redirectLoginUri)
            .set('code', code)
            .set('code_verifier', codeVerifier)
            .set('client_secret', environment.clientSecret);

        return this.httpClient
            .post<LoginResponse>(tokenUrl, params.toString(), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .pipe(catchError(this.handleError));
    }

    resetPasswordUsingKeycloak(
        userId: string,
        password: string
    ): Observable<any> {
        const payload = {
            type: 'password',
            value: password,
            temporary: false,
        };
        return this.httpClient
            .put(
                `/admin/realms/${environment.realm}/users/${userId}/reset-password`,
                payload
            )
            .pipe(catchError(this.handleError));
    }

    public parseJwt(token: string): ParseJwt {
        const base64Url = token.split('.')[1];
        const base64 = decodeURIComponent(
            window
                .atob(base64Url)
                .split('')
                .map(function (c) {
                    return (
                        '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                    );
                })
                .join('')
        );
        return JSON.parse(base64);
    }

    logout(userId?: string) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        return this.httpClient
            .post<any>(
                `/admin/realms/${environment.realm}/users/${userId}/logout`,
                null,
                {
                    observe: 'response',
                    headers,
                }
            )
            .pipe(
                tap(() => {
                    this.router.navigate(['/authentication/logout']);
                    this.setUserAccessToken('');
                    this.appStore.setAccessToken('');
                }),
                catchError(this.handleError)
            );
    }

    /**
     * Handle HTTP error
     */
    private handleError(error: HttpErrorResponse): Observable<never> {
        let errorMessage = '';

        if (error.error instanceof ErrorEvent) {
            // Client-side or network error
            errorMessage = `Client-side error: ${error.error.message}`;
        } else if (error.status === 0) {
            // Network error (e.g., no internet)
            errorMessage = `Server error: Please try again later.`;
        } else if (error.error?.message) {
            // Server-side error
            // Assuming the API returns a JSON response with a 'message' field

            errorMessage = error.error.message;
        } else if (error.error?.error_description) {
            errorMessage = error.error.error_description;
        } else if (error.error?.errorMessage) {
            errorMessage = error.error?.errorMessage;
        } else if (error?.message) {
            errorMessage = error.message;
        }
        // Return the error message to the UI
        return throwError(() => errorMessage);
    }
}
